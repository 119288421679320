import { Component } from '@angular/core';

@Component({
  selector: 'app-become-premium-member',
  templateUrl: './become-premium-member.component.html',
  styleUrls: ['./become-premium-member.component.scss'],
})
export class BecomePremiumMemberComponent {
  signUpToPremium() {
    window.location.href = 'https://account.teuteuf.fr/premium-sign-up';
  }
}
