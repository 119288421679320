import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { ArchiveService } from 'src/app/services/archive.service';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
  userDetails$: Observable<UserDetails>;

  socialLogins = [
    {
      name: 'X',
      path: 'twitter',
      icon: 'assets/icons/X.svg',
      className: 'bg-black text-white',
    },
    {
      name: 'Google',
      path: 'google',
      icon: 'assets/icons/google.png',
      className: 'bg-[#4285f4] text-white',
    },
    {
      name: 'Apple',
      path: 'apple',
      icon: 'assets/icons/apple.png',
      className: 'bg-black text-white',
    },
  ];

  constructor(
    private user: UserService,
    private archive: ArchiveService,
    private db: DatabaseService
  ) {
    this.userDetails$ = this.user.getUserDetails();
  }

  signOut() {
    this.user.signOut();
  }

  replayTodaysGame() {
    this.archive.replayTodaysGame();
  }

  getLoginHref(socialLoginPath: string): string {
    return `${this.db.getAuthServerURL()}/${socialLoginPath}?referer=${this.db.getReferer()}`;
  }
}
