import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogsService } from './dialogs.service';

declare global {
  interface Window {
    adngin?: any;
    destroyRefreshTopAd?: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdsService {
  constructor(
    private platform: PlatformService,
    private dialog: DialogsService
  ) {}

  async loadAdScriptAndDetectAdBlocker() {
    console.log('Loading ad script');
    if (!this.platform.isInBrowser()) return;
    const href = window.location.href;
    let src = '';
    if (href.includes('staging')) {
      console.log('Loading STAGING ads script');
      src =
        'https://staging-cdn.snigelweb.com/adengine/wheretaken.teuteuf.fr/loader.js';
    } else {
      console.log('Loading LIVE ads script');
      src =
        'https://cdn.snigelweb.com/adengine/wheretaken.teuteuf.fr/loader.js';
    }
    let node = document.createElement('script');
    node.src = src;
    node.type = 'text/javascript';
    node.async = true;
    node.setAttribute('data-cfasync', 'false');
    document.getElementsByTagName('head')[0].appendChild(node);

    // check for ad block
    // const adBlockerDetected = await this.detectAdBlocker();
    // if (adBlockerDetected) this.showAntiAdBlockWarning();
  }

  destroyTopAd() {
    if (typeof window.destroyRefreshTopAd === 'function') {
      try {
        window.destroyRefreshTopAd();
      } catch (e) {
        console.error(e);
      }
    }
  }

  startAuction(adName: string, device: string) {
    if (device === 'desktop' && window.innerWidth < 1280) {
      return;
    } else if (device === 'mobile' && window.innerWidth >= 1280) {
      return;
    }
    setTimeout(() => {
      if (typeof window.adngin.cmd === 'object') {
        try {
          if (window.adngin && window.adngin.adnginLoaderReady) {
            console.log('Starting auction for ' + adName);
            window.adngin.cmd.startAuction([adName]);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, 500);
  }

  async detectBraveBrowser(): Promise<boolean> {
    if ((navigator as any).brave) {
      return (navigator as any).brave
        .isBrave()
        .then((isBrave: boolean) => isBrave);
    }
    return false;
  }

  async detectAdBlocker(): Promise<boolean> {
    const isBrave = await this.detectBraveBrowser();
    if (isBrave) return true;

    return new Promise((resolve) => {
      let detected = false;

      // 1. attempt to load an ad script
      const testScript = document.createElement('script');
      testScript.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      testScript.type = 'text/javascript';
      testScript.async = true;

      testScript.onerror = () => {
        if (!detected) {
          detected = true;
          resolve(true);
        }
      };

      document.body.appendChild(testScript);

      // 2. check if the request was actually made
      setTimeout(() => {
        const entries = performance.getEntriesByType('resource');
        const adRequest = entries.find((entry) =>
          entry.name.includes('pagead2.googlesyndication.com')
        );

        if (!adRequest && !detected) {
          detected = true;
          resolve(true);
        }

        document.body.removeChild(testScript);

        // no ad blocker detected
        if (!detected) {
          resolve(false);
        }
      }, 2000);
    });
  }

  showAntiAdBlockWarning() {
    // if user has seen toast in the last 3 days, don't show toast
    const storedLastSeenAdBlockerToast =
      localStorage.getItem('ad-blocker-toast');
    const lastSeenToast = storedLastSeenAdBlockerToast
      ? JSON.parse(storedLastSeenAdBlockerToast)
      : null;
    if (lastSeenToast && Date.now() - lastSeenToast < 1000 * 60 * 60 * 24 * 3) {
      return;
    }

    this.dialog.openAntiAdBlockWarning();
    localStorage.setItem('ad-blocker-toast', JSON.stringify(Date.now()));
  }
}
