
import { Data } from '../interfaces/Data';
import { UserDetails } from '../interfaces/UserDetails';
import {
  ArchiveGame,
  dailyStats,
  Game,
  Settings,
  Stats,
} from '../interfaces/interfaces';


export const globalStats: dailyStats = {
  totalGames: 0,
  guesses: [0, 0, 0, 0, 0, 0, 0],
  highest: 0,
  common: [
    {
      country: 'Loading countries',
      total: 0,
    },
  ],
};

export const settings: Settings = {
  difficulty: 'normal',
};

export const stats: Stats = {
  played: 0,
  win: 0,
  winPercent: 0,
  currentStreak: 0,
  lastWinDate: new Date().toISOString(),
  maxStreak: 0,
  gameNumber: 0,
  guessDist: [0, 0, 0, 0, 0, 0],
};

export const archiveGame: ArchiveGame = {
  isArchiveGame: false,
  date: '',
};

export const data: Data = {
  userFetched: false,
  isArchiveGame: false,
}

export const userDetails: UserDetails = {
  loggedIn: false,
  firstName: '',
  photoURL: '',
  isPhotoValid: false,
  marketingOptIn: '',
  wheretaken: '',
  wheretakenusa: '',
  worldle: '',
  flagle: '',
  statele: '',
  emovi: '',
  premiumGames: [],
}

export const initialData: Game = {
  newGame: true,
  previousPage: '/',
  country: '',
  countryCode: '',
  gameType: 'country',
  gameMode: 'Daily',
  showAlert: false,
  showShare: false,
  showAnswer: false,
  gameNumber: 0,
  date: '',
  guessed: false,
  imgSrc: '',
  imgLink: '',
  wikiLink: '',
  lat: 0,
  long: 0,
  guessValue: '',
  guessNumber: 0,
  distUnit: '',
  shownConfetti: false,
  gameWon: false,
  difficulty: 'normal',
  turtleSrc: '',
  showLinks: false,
  topAdOpen: true,
  capitals: [],
  hasCityRound: true,
  hasCapitalRound: true,
  data: {
    supportsWebP: false,
    imgExtension: 'jpg',
  },
  stars: {
    number: 0,
    percent: 0,
    stars: [
      {
        isGuessed: false,
        shownAnimation: false,
      },
      {
        isGuessed: false,
        shownAnimation: false,
      },
      {
        isGuessed: false,
        shownAnimation: false,
      },
      {
        isGuessed: false,
        shownAnimation: false,
      },
    ],
  },
  landmarks: {
    guessed: false,
    guesses: 0,
    buttons: [false, false, false, false, false, false],
    answer: '',
    answerSrc: '',
    locations: [],
    roundOver: false,
    shownConfetti: false,
    guessesRemaining: 2,
    zoom: false,
    zoomImage: '',
    imgSrc: '',
    wikiLink: '',
  },
  landmarksGuess: {
    showRound: false,
    shownConfetti: false,
    guessed: false,
    shake: false,
    roundOver: false,
    guessesRemaining: 2,
    landmarks: [],
  },
  cityGuess: {
    imgSrc: '',
    noImage: false,
    guessed: false,
    roundOver: false,
    guessesRemaining: 2,
    shownConfetti: false,
    cities: [],
    imageLink: '',
    wikiLink: '',
  },
  capitalCity: {
    guessed: false,
    guessValue: '',
    answers: [],
    shownConfetti: false,
    showConfetti: false,
    roundOver: false,
    showAnswer: false,
    guessNumber: 0,
    imgSrc: '',
    wikiLink: '',
    guesses: [
      {
        id: 1,
        city: '',
        isCorrect: false,
        isBlank: true,
        isLoading: false,
        isGuessed: false,
        squares: ['grey', 'grey', 'grey', 'grey', 'grey'],
      },
      {
        id: 2,
        city: '',
        isCorrect: false,
        isBlank: true,
        isLoading: false,
        isGuessed: false,
        squares: ['grey', 'grey', 'grey', 'grey', 'grey'],
      },
      {
        id: 3,
        city: '',
        isCorrect: false,
        isBlank: true,
        isLoading: false,
        isGuessed: false,
        squares: ['grey', 'grey', 'grey', 'grey', 'grey'],
      },
    ],
  },
  map: {
    showRound: false,
    guessed: false,
    location: '',
    incorrectGuesses: [],
    shake: false,
    correctGuesses: [],
    shownConfetti: false,
    guessesRemaining: 2,
    roundOver: false,
  },
  guesses: [
    {
      id: 0,
      distance: 0,
      distanceMI: 0,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 1,
      distance: 0,
      distanceMI: 0,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 2,
      distance: 0,
      distanceMI: 0,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 3,
      distance: 0,
      distanceMI: 0,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 4,
      distance: 0,
      distanceMI: 0,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 5,
      distance: 0,
      distanceMI: 0,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
  ],
};
