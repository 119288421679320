<mat-dialog-content>
  <div class="text-black dark:!text-white p-2">
    <div class="absolute right-4 top-4">
      <button (click)="closeDialog()" mat-button class="font-bold rounded">
        &#x2715;
      </button>
    </div>
    <h3 class="font-bold text-center mt-3 mb-3">
      🥵 Oh no! 🥵
    </h3>
    <div class="mb-3 flex flex-col text-sm text-gray-900 dark:text-white gap-3">
      <p>
        It looks like you&apos;re using an ad blocker. No worries - we
        totally get it! However, ads help keep WhereTaken Classic free for everyone
        by paying for servers, supporting development, and helping us bring
        you new features and games.
      </p>
      <p>
        So, if you&apos;d like to support us and keep enjoying the game for
        free, please consider turning off your ad blocker or, if you really
        hate ads, subscribe to our premium plan for an ad-free experience!
      </p>
      <p>We appreciate your support! ❤️</p>
      <p>- Teuteuf Team</p>
    </div>
    <div class="w-full flex flex-col justify-center items-center gap-2">
      <button (click)="reload()"
        class="inline-flex items-center justify-center w-full h-[35px] px-2 py-1.5 text-sm font-semibold text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">
        I already turned off my ad blocker
      </button>
      <a href="https://account.teuteuf.fr/premium-sign-up" target="_blank" rel="noreferrer"
        class="inline-flex items-center justify-center w-full h-[35px] px-2 py-1.5 text-sm font-semiboldtext-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:text-white dark:border-gray-700 dark:hover:bg-gray-500 dark:focus:ring-gray-600">
        Subscribe to Premium
      </a>
    </div>
  </div>
</mat-dialog-content>