import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'anti-ad-block-warning-dialog',
  templateUrl: './anti-ad-block-warning.component.html',
  styleUrls: ['./anti-ad-block-warning.component.scss'],
})
export class AntiAdBlockWarningComponent {
  constructor(public dialogRef: MatDialogRef<AntiAdBlockWarningComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }

  reload() {
    window.location.reload();
  }
}
