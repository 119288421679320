<div *ngIf="user$ | async as user" class="mt-3 text-black dark:!text-white relative flex flex-col gap-3">
  <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>

  <h2 class="text-xl font-bold">Remove ads</h2>
  <div *ngIf="user?.premiumGames?.includes('wheretaken'); else notPremium">

  </div>
  <ng-template #notPremium>
    <div>
      <p class="mb-3">Removal of ads is an exclusive feature for Premium Members. Sign up now to experience all the benefits.</p>
      <app-become-premium-member></app-become-premium-member>
    </div>
  </ng-template>
</div>
