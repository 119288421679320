import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss']
})
export class LoggedInComponent {

  params!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private user: UserService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Logged in - WhereTaken Classic');
  }

  ngOnInit(): void {
    this.user.userFetched();
    this.params = this.route.fragment.pipe().subscribe(async (fragment) => {
      if (fragment) {
        const params = new URLSearchParams(fragment);
        const rawUserData = params.get('userData');

        if (rawUserData) {
          await this.user.getUserDetailsFromDatabase();
          this.router.navigate(['/']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.params && this.params.unsubscribe();
  }
}
